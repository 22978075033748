<script>
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";
import Horizontal from "./horizontal";
import { required, numeric, sameAs, maxLength, minLength } from "vuelidate/lib/validators";
import confirmModal from "@/components/confirm.vue";
import Receipt from '@/components/receipt.vue';

export default {
    components: {
        Horizontal,
        confirmModal,
        Receipt,
    },
    data() {
        return {
            currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
            // Check privilege form
            identity_number: "",

            // Register form
            vs_identity: `VS${String(JSON.parse(localStorage.getItem('auth.currentUser')).uid).padStart(6, "0")}`,
            firstname: "",
            lastname: "",
            phone: "",
            otp: "",
            verify_otp: "",
            birthday: "",
            national: "",
            religion: "",
            address: "",
            license1: "",
            license1_date: "",
            license2: "",
            license2_date: "",
            license3: "",
            license3_date: "",
            license4: "",
            license4_date: "",

            // form disable flag
            disabledCheckingAndForm: false,
            phoneChecked: false,

            tryingToCheckPrivilege: false,
            submittedCheckPrivilege: false,

            tryingToRegisted: false,
            submittedRegisted: false,

            privilegeCheckError: null,
            isPrivilegeCheckError: false,

            isShowRegisterForm: false,
            isShowPaymentForm: false,

            completeAllField: false,

            completePayment: false,
            payment: {
                token: process.env.VUE_APP_GB_TOKEN,
                backgroundUrl: process.env.VUE_APP_GB_BACKGROUND_URL,
            },

            onFreezData: false,

            isShowConfirmModal: false,
            showReceiptModal: false,
        };
    },
    validations: {
        identity_number: {
            required,
            numeric,
            maxLength: maxLength(13),
            minLength: minLength(13),
        },
        vs_identity: {
            required,
        },
        firstname: {
            required,
        },
        lastname: {
            required,
        },
        phone: {
            required,
            numeric,
            maxLength: maxLength(10),
            minLength: minLength(10),
        },
        otp: {
            required,
            numeric,
            sameAs: sameAs(function () { return this.verify_otp }),
        },
        birthday: {
            required,
        },
        national: {
            required,
        },
        religion: {
            required,
        },
        address: {
            required,
        },
    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    created() {
        this.$emit("reloadUserData");
    },
    mounted() {
        document.body.classList.add('authentication-bg');
        console.log("this.currentUser:", this.currentUser);
        console.log("work");

        if (this.currentUser.teacher_info != {} && this.currentUser.teacher_info != undefined && this.currentUser.teacher_info != null && this.currentUser.teacher_info.id >= 0) {
            this.completeAllField = true;
            this.vs_identity = this.currentUser.username;
            this.identity_number = this.currentUser.teacher_info.identity_number;
            this.firstname = this.currentUser.teacher_info.firstname;
            this.lastname = this.currentUser.teacher_info.lastname;
            this.phone = this.currentUser.teacher_info.phone;
            this.birthday = this.currentUser.teacher_info.birthday;
            this.national = this.currentUser.teacher_info.national;
            this.religion = this.currentUser.teacher_info.religion;
            this.address = this.currentUser.teacher_info.address;
            this.license1 = this.currentUser.teacher_info.license1;
            this.license1_date = this.currentUser.teacher_info.license1_date;
            this.license2 = this.currentUser.teacher_info.license2;
            this.license2_date = this.currentUser.teacher_info.license2_date;
            this.license3 = this.currentUser.teacher_info.license3;
            this.license3_date = this.currentUser.teacher_info.license3_date;
            this.license4 = this.currentUser.teacher_info.license4;
            this.license4_date = this.currentUser.teacher_info.license4_date;

            if (this.currentUser.teacher_info.resultCode == "00") {
                this.completePayment = true;
            } else {
                this.isShowPaymentForm = true;
            }
        }

        // tempo
        this.isShowRegisterForm = true;
        this.disabledCheckingAndForm = true;
    },
    methods: {
        getProfileImage() {
            if (this.user.profile_image == null || this.user.profile_image == undefined) {
                return require('@/assets/images/users/anonymous.png');
            }
            return this.user.profile_image;
        },
        // eslint-disable-next-line no-unused-vars
        tryToCheckPrivilege() {
            if (this.completeAllField) return;

            this.submittedCheckPrivilege = true;

            this.$v.identity_number.$touch();
            this.$v.firstname.$touch();
            this.$v.lastname.$touch();
            this.$v.phone.$touch();
            this.$v.otp.$touch();

            if (this.$v.identity_number.$invalid || this.$v.firstname.$invalid || this.$v.lastname.$invalid || this.$v.phone.$invalid || this.$v.otp.$invalid) {
                console.log("this.$v.$invalid:", this.$v.$invalid);
                console.log('form is invalid');
                return;
            }

            // tempory disable checking privilege

            // this.tryingToCheckPrivilege = true;
            // this.privilegeCheckError = null;
            // return (
            //     this.$store.dispatch("apiRestful/checkPrivilege", {
            //         identity_number: this.identity_number,
            //     })
            //         // eslint-disable-next-line no-unused-vars
            //         .then((resp) => {
            //             console.log("resp:", resp);
            //             this.tryingToCheckPrivilege = false;
            //             this.privilegeCheckError = false;
            //             console.log("OK");

            //             if (resp.status == 409) {
            //                 alert(resp.msg);
            //                 return;
            //             }

            //             if (resp.id >= 0) {
            //                 this.isShowRegisterForm = true;
            //                 this.disabledCheckingAndForm = true;
            //                 return;
            //             }
            //             this.isShowRegisterForm = false;
            //             this.disabledCheckingAndForm = false;
            //             this.tryingToCheckPrivilege = false;
            //             this.privilegeCheckError = false;
            //         })
            //         .catch((error) => {
            //             this.tryingToCheckPrivilege = false;
            //             this.privilegeCheckError = error ? error : "";
            //             this.isPrivilegeCheckError = true;

            //             this.disabledCheckingAndForm = false;
            //             this.isShowRegisterForm = false;
            //         })
            // );
        },
        tryToRegistedTeacher() {
            this.tryingToRegisted = true;
            this.registedError = null;
            return (
                this.$store.dispatch("apiRestful/registedTeacher", {
                    identity_number: this.identity_number,
                    uid: this.currentUser.uid,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    phone: this.phone,
                    birthday: this.birthday,
                    national: this.national,
                    religion: this.religion,
                    address: this.address,
                    license1: this.license1,
                    license1_date: this.license1_date,
                    license2: this.license2,
                    license2_date: this.license2_date,
                    license3: this.license3,
                    license3_date: this.license3_date,
                    license4: this.license4,
                    license4_date: this.license4_date
                })
                    // eslint-disable-next-line no-unused-vars
                    .then((resp) => {
                        console.log("resp:", resp);
                        if (resp.is_created) {
                            alert("ลงทะเบียนสมบูรณ์  กรุณาชำระค่าสมัคร โดยสามารถเลือกได้ว่า จะจ่ายผ่าน QR Code หรือจ่ายผ่าน ใบชำระเงิน ได้ที่เคาเตอร์ชำระบิล ATM หรือ Application ธนาคารที่สามารถแสกนบาร์โค๊ดได้");
                            this.$emit("reloadUserData");
                            setTimeout(() => {
                                this.currentUser = JSON.parse(localStorage.getItem('auth.currentUser'));
                                this.isShowPaymentForm = true;
                                this.completeAllField = true;
                                this.submittedRegisted = false;
                                window.location.reload();
                            }, 2000);
                            return;
                        }
                        alert("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                        this.registedError = null;
                        this.isRegistedError = false;
                        this.tryingToRegisted = false;
                        this.$emit("reloadUserData");
                        this.isShowPaymentForm = false;
                        this.completeAllField = false;
                        this.submittedRegisted = false;
                        return;

                    })
                    .catch((error) => {
                        this.tryingToCheckPrivilege = false;
                        this.registedError = error ? error : "";
                        this.isRegistedError = true;
                    })
            );
        },
        inspectForm() {
            if (this.completeAllField) return;
            this.$refs.formCheckProvilege.requestSubmit();

            this.submittedRegisted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                console.log('form is invalid');
                alert("กรุณาตรวจสอบข้อมูลให้ถูกต้อง");
                return;
            }

            this.completeAllField = true;
        },
        verifyPhone() {
            if (this.phoneChecked || this.completeAllField) {
                return;
            }
            this.$v.phone.$touch();

            if (this.$v.phone.$invalid) {
                console.log('form is invalid');
                return;
            }
            return (
                this.$store.dispatch("apiRestful/verifyPhone", {
                    phone: this.phone,
                })
                    // eslint-disable-next-line no-unused-vars
                    .then((resp) => {
                        console.log("resp:", resp);
                        this.verify_otp = resp.otp;
                    })
                    .catch((error) => {
                        console.log('error:', error);
                    })
            );

        },
        checkMatchOTP() {
            if (this.completeAllField) return;
            if (this.otp == this.verify_otp && this.otp.length > 0 && this.verify_otp.length > 0) {
                this.phoneChecked = true;
                return true;
            }
            return false;
        },
        downloadPDF() {
            window.open(this.currentUser.teacher_info.pdf_url, '_blank', 'noreferrer');
            return;
        },
        closeConfirmModal() {
            this.isShowConfirmModal = !this.isShowConfirmModal;
            this.completeAllField = false;
            return;
        },
        confirmData() {
            this.isShowConfirmModal = !this.isShowConfirmModal;
            this.tryToRegistedTeacher();
            return;
        },
        showForm(){
            const now = new Date();
            const due = new Date("2023-12-09 00:00:00");
            if(now.getTime() < due.getTime() || !this.completePayment){
                return true;
            }
            return false;
        },
        isDuedate(){
            const now = new Date();
            const due = new Date("2023-12-09 00:00:00");
            if(now.getTime() > due.getTime()){
                return true;
            }
            return false;
        }
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType">
            <div class="row">
                <div class="col-12">
                    <!-- <h1 class="text-center">อยู่ระหว่างการทดสอบระบบ</h1> -->
                    <div class="card">
                        <div class="card-body text-start">
                            <div v-if="showForm()">
                                <div v-if="!isDuedate()">
                                    <h5 class="text-primary text-center">
                                        กรุณากรอกข้อมูล<br />
                                        เพื่อสมัครอบรมครูแพทย์แผนไทย
                                    </h5>
                                    <p class="text-muted text-center">(ต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย)</p>
                                    <div class="my-3">
    
                                        <b-form ref="formCheckProvilege" @submit.prevent="tryToCheckPrivilege">
                                            <b-form-group class="mb-3" label="กรุณากรอก ชื่อ">
                                                <b-form-input v-model="firstname" type="text" placeholder="ชื่อจริง"
                                                    :class="{ 'is-invalid': submittedCheckPrivilege && $v.firstname.$error }"
                                                    :readonly="completeAllField"></b-form-input>
                                                <div v-if="submittedCheckPrivilege && $v.firstname.$error"
                                                    class="invalid-feedback">
                                                    <div v-if="!$v.firstname.required">ต้องกรอกชื่อ</div>
                                                </div>
                                            </b-form-group>
    
                                            <b-form-group class="mb-3" label="กรุณากรอก นามสกุล">
                                                <b-form-input v-model="lastname" type="text" placeholder="นามสกุลจริง"
                                                    :class="{ 'is-invalid': submittedCheckPrivilege && $v.lastname.$error }"
                                                    :readonly="completeAllField"></b-form-input>
                                                <div v-if="submittedCheckPrivilege && $v.lastname.$error"
                                                    class="invalid-feedback">
                                                    <div v-if="!$v.lastname.required">ต้องกรอกนามสกุล</div>
                                                </div>
                                            </b-form-group>
    
                                            <b-form-group class="mb-3" label="กรุณากรอก เลขประจำตัวประชาชน 13 หลัก">
                                                <b-form-input v-model="identity_number" type="text"
                                                    placeholder="เลขประจำตัวประชาขน 13 หลัก เช่น 1002345678991 เป็นต้น"
                                                    :class="{ 'is-invalid': submittedCheckPrivilege && $v.identity_number.$error }"
                                                    :readonly="completeAllField" maxlength="13"></b-form-input>
                                                <div v-if="submittedCheckPrivilege && $v.identity_number.$error"
                                                    class="invalid-feedback">
                                                    <div v-if="!$v.identity_number.required">ต้องกรอกหมายเลขบัตรประชาชน</div>
                                                    <div v-if="!$v.identity_number.numeric">
                                                        ต้องกรอกหมายเลขบัตรประชาชนเป็นตัวเลขเท่านั้น</div>
                                                    <div v-if="!$v.identity_number.maxLength">ต้องกรอกหมายเลขบัตรประชาชน
                                                        ไม่เกินกว่า
                                                        13 หลัก</div>
                                                    <div v-if="!$v.identity_number.minLength">ต้องกรอกหมายเลขบัตรประชาชน
                                                        ไม่น้อยกว่า
                                                        13 หลัก</div>
                                                </div>
                                            </b-form-group>
    
                                            <div class="row">
                                                <div class="col-6">
                                                    <b-form-group class="mb-3" label="เบอร์โทรศัพท์">
                                                        <b-form-input v-model="phone" type="text"
                                                            placeholder="เบอร์โทรศัพท์ที่ใช้งานสำหรับให้สภาการแพทย์แผนไทย สามารถติดต่อกลับได้"
                                                            :class="{ 'is-invalid': $v.phone.$error }"
                                                            :readonly="phoneChecked || completeAllField"
                                                            maxlength="10"></b-form-input>
                                                        <div v-if="$v.phone.$error" class="invalid-feedback">
                                                            <div v-if="!$v.phone.required">ต้องกรอกเบอร์โทรศัพท์</div>
                                                            <div v-if="!$v.phone.numeric">
                                                                ต้องกรอกเบอร์โทรศัพท์เป็นตัวเลขเท่านั้น
                                                            </div>
                                                            <div v-if="!$v.phone.maxLength">ต้องกรอกเบอร์โทรศัพท์ ไม่เกินกว่า 10
                                                                หลัก</div>
                                                            <div v-if="!$v.phone.minLength">ต้องกรอกเบอร์โทรศัพท์ ไม่น้อยกว่า 10
                                                                หลัก</div>
                                                        </div>
    
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6">
                                                    <b-form-group v-if="!completeAllField" class="mb-3"
                                                        label="รหัส OTP ที่ได้รับ">
                                                        <b-form-input v-model="otp" type="text"
                                                            placeholder="ระบุรหัส OTP ที่ได้รับ"
                                                            :class="{ 'is-invalid': (submittedCheckPrivilege && $v.otp.$error) }"
                                                            :readonly="phoneChecked || completeAllField"
                                                            @blur="checkMatchOTP"></b-form-input>
                                                        <div v-if="submittedCheckPrivilege && $v.otp.$error"
                                                            class="invalid-feedback">
                                                            <span v-if="!$v.otp.required">ต้องกรอกรหัส OTP
                                                                ที่ได้รับจากระบบ</span>
                                                            <span v-if="!$v.otp.sameAs">OTP ต้องตรงกับ OTP ที่ส่งไป</span>
                                                        </div>
                                                        <b-input-group-append v-if="!completeAllField"
                                                            class="mt-2 d-grid d-block">
                                                            <b-button type="button" @click="verifyPhone" variant="info"
                                                                :disable="phoneChecked || completeAllField">ขอรับ OTP</b-button>
                                                        </b-input-group-append>
                                                    </b-form-group>
                                                </div>
                                            </div>
    
                                            <!-- <div v-if="!completeAllField" class="mt-3 text-center d-block d-grid"> -->
                                            <div v-if="false" class="mt-3 text-center d-block d-grid">
                                                <b-button type="submit" variant="warning" class="w-xl"
                                                    :disabled="disabledCheckingAndForm || completeAllField">ตรวจสอบสิทธิ</b-button>
                                            </div>
                                        </b-form>
                                    </div>
    
                                    <div class="my-3" v-if="isShowRegisterForm || completeAllField">
    
                                        <b-form @submit.prevent="inspectForm">
                                            <b-form-group class="mb-3" label="รหัสผู้ใช้ระบบ">
                                                <b-form-input v-model="vs_identity" type="text" placeholder="VSXXXXXX"
                                                    :class="{ 'is-invalid': submittedRegisted && $v.vs_identity.$error }"
                                                    :readonly="true"></b-form-input>
                                            </b-form-group>
    
    
    
                                            <b-form-group class="mb-3" label="วัน เดือน ปี เกิด">
                                                <b-form-input v-model="birthday" type="text"
                                                    placeholder="วัน เดือน ปี เกิด เช่น 01/01/2500"
                                                    :class="{ 'is-invalid': submittedRegisted && $v.birthday.$error }"
                                                    :readonly="completeAllField"></b-form-input>
                                                <div v-if="submittedRegisted && $v.birthday.$error" class="invalid-feedback">
                                                    <span v-if="!$v.birthday.required">ต้องกรอกวัน เดือน ปี เกิด</span>
                                                </div>
                                            </b-form-group>
    
                                            <b-form-group class="mb-3" label="สัญชาติ">
                                                <b-form-input v-model="national" type="text" placeholder="สัญชาติ"
                                                    :class="{ 'is-invalid': submittedRegisted && $v.national.$error }"
                                                    :readonly="completeAllField"></b-form-input>
                                                <div v-if="submittedRegisted && $v.national.$error" class="invalid-feedback">
                                                    <span v-if="!$v.national.required">ต้องกรอกสัญชาติ</span>
                                                </div>
                                            </b-form-group>
    
                                            <b-form-group class="mb-3" label="ศาสนา">
                                                <b-form-input v-model="religion" type="text" placeholder="ศาสนา"
                                                    :class="{ 'is-invalid': submittedRegisted && $v.religion.$error }"
                                                    :readonly="completeAllField"></b-form-input>
                                                <div v-if="submittedRegisted && $v.religion.$error" class="invalid-feedback">
                                                    <span v-if="!$v.religion.required">ต้องกรอกศาสนา</span>
                                                </div>
                                            </b-form-group>
    
                                            <b-form-group class="mb-3" label="ที่อยู่ที่สามารถติดต่อได้">
                                                <b-form-textarea v-model="address" type="text"
                                                    placeholder="กรอกที่อยู่ที่สามารถติดต่อได้ เช่น 111/11 หมู่ 1 ตำบลหนึ่ง อำเภอหนึ่ง จังหวัดหนึ่ง รหัสไปรษณีย์ 11111"
                                                    :class="{ 'is-invalid': submittedRegisted && $v.address.$error }"
                                                    :readonly="completeAllField"></b-form-textarea>
                                                <div v-if="submittedRegisted && $v.address.$error" class="invalid-feedback">
                                                    <span v-if="!$v.address.required">ต้องกรอกที่อยู่ที่สามารถติดต่อได้</span>
                                                </div>
                                            </b-form-group>
    
                                            <hr class="my-3" />
    
                                            <h5>กรุณา ระบุข้อมูลใบประกอบวิชาชีพการแพทย์แผนไทย ของท่าน ให้ถูกต้อง</h5>
                                            <b-form-group class="mb-3" label="ด้านเวชกรรมไทย">
                                                <b-form-input v-model="license1" type="text"
                                                    placeholder="ใบอนุญาตฯเวชกรรมไทยเลขที่"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <b-form-group class="mb-3" label="ออกให้ ณ วันที่">
                                                <b-form-input v-model="license1_date" type="text"
                                                    placeholder="ออกให้ ณ วันที่ เช่น 12/03/2550"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <hr class="my-3" />
                                            <b-form-group class="mb-3" label="ด้านเภสัชกรรมไทย">
                                                <b-form-input v-model="license2" type="text"
                                                    placeholder="ใบอนุญาตฯเภสัชกรรมไทยเลขที่"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <b-form-group class="mb-3" label="ออกให้ ณ วันที่">
                                                <b-form-input v-model="license2_date" type="text"
                                                    placeholder="ออกให้ ณ วันที่ เช่น 12/03/2550"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <hr class="my-3" />
                                            <b-form-group class="mb-3" label="ด้านผดุงครรภ์ไทย">
                                                <b-form-input v-model="license3" type="text"
                                                    placeholder="ใบอนุญาตฯผดุงครรภ์ไทยเลขที่"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <b-form-group class="mb-3" label="ออกให้ ณ วันที่">
                                                <b-form-input v-model="license3_date" type="text"
                                                    placeholder="ออกให้ ณ วันที่ เช่น 12/03/2550"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <hr class="my-3" />
                                            <b-form-group class="mb-3" label="ด้านการนวดไทย">
                                                <b-form-input v-model="license4" type="text"
                                                    placeholder="ใบอนุญาตฯการนวดไทยเลขที่"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
                                            <b-form-group class="mb-3" label="ออกให้ ณ วันที่">
                                                <b-form-input v-model="license4_date" type="text"
                                                    placeholder="ออกให้ ณ วันที่ เช่น 12/03/2550"
                                                    :readonly="completeAllField"></b-form-input>
                                            </b-form-group>
    
                                            <hr class="my-3" />
                                            <h6>
                                                <u>ข้อสงวนสิทธิในการลงทะเบียน</u><br />
                                                กรุณาตรวจสอบคุณสมบัติในการลงทะเบียนของท่านก่อนชำระเงิน
                                                หากตรวจสอบพบในภายหลังว่าท่านมีคุณสมบัติไม่ครบถ้วน สภาการแพทย์แผนไทย
                                                ขอสงวนสิทธิในการยกเลิกสิทธิการลงทะเบียนและสิทธิที่เกี่ยวข้อง
                                                รวมถึงการรับใบประกาศหรือการรับรองที่เกี่ยวข้อง
                                                โดยจะไม่คืนเงินค่าธรรมเนียมไม่ว่าในกรณีใดๆทั้งสิ้น
                                                ทั้งนี้ไม่จำต้องบอกกล่าวล่วงหน้า
                                                อีกทั้งถือว่าผู้สมัครตกลงสละสิทธิเรียกร้องในการดำเนินคดีใดๆไม่ว่าทางแพ่งหรืออาญาต่อสภาการแพทย์แผนไทยและผู้ที่เกี่ยวข้อง
                                            </h6>
                                            <div v-if="!completeAllField" class="mt-3 text-end">
                                                <b-button type="submit" variant="success" class="w-sm"
                                                    :disabled="tryingToRegisted || completeAllField">ตรวจสอบข้อมูล</b-button>
                                            </div>
                                        </b-form>
                                    </div>
    
                                    <div class="my-3" v-if="completeAllField && submittedRegisted">
                                        <hr />
                                        <div class="row">
                                            <div class="col-6 d-block d-grid">
                                                <b-button type="button"
                                                    @click="() => { completeAllField = !completeAllField; submittedRegisted = !submittedRegisted; }"
                                                    lg="5" variant="warning">แก้ไข</b-button>
                                            </div>
                                            <div class="col-6 d-block d-grid">
                                                <b-button type="button" @click="isShowConfirmModal = !isShowConfirmModal" lg="5"
                                                    variant="danger">ยืนยัน</b-button>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="my-3"
                                        v-if="isShowPaymentForm && !completePayment && !!currentUser.teacher_info.amount">
                                        <hr />
                                        <h5>เลือกการชำระเงิน</h5>
                                        <div class="row">
                                            <div class="col-6 d-block d-grid">
                                                <b-form class="d-block d-grid" method="POST" target="_blank"
                                                    action="https://api.gbprimepay.com/gbp/gateway/qrcode"
                                                    enctype="application/x-www-form-urlencoded">
                                                    <input type="hidden" name="token" :value="payment.token" />
                                                    <input type="hidden" name="backgroundUrl" :value="payment.backgroundUrl" />
                                                    <input type="hidden" name="amount"
                                                        :value="(currentUser.teacher_info.amount + currentUser.teacher_info.fee).toFixed(2)" />
                                                    <input type="hidden" name="referenceNo"
                                                        :value="currentUser.teacher_info.referenceNo" />
                                                    <b-button type="submit" lg="5" variant="primary">จ่ายผ่าน QR Code</b-button>
                                                </b-form>
                                            </div>
                                            <div class="col-6 d-block d-grid">
                                                <b-form class="d-block d-grid" method="POST" target="_blank"
                                                    action="https://api.gbprimepay.com/v3/barcode"
                                                    enctype="application/x-www-form-urlencoded">
                                                    <input type="hidden" name="token" :value="payment.token" />
                                                    <input type="hidden" name="backgroundUrl" :value="payment.backgroundUrl" />
                                                    <input type="hidden" name="amount"
                                                        :value="(currentUser.teacher_info.amount + currentUser.teacher_info.fee).toFixed(2)" />
                                                    <input type="hidden" name="referenceNo"
                                                        :value="currentUser.teacher_info.referenceNo" />
                                                    <b-button type="submit" lg="5" variant="primary">จ่ายผ่าน
                                                        ใบชำระเงิน</b-button>
                                                </b-form>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="my-3" v-if="completePayment">
                                        <hr />
                                        <h5>ชำระเงิน เรียบร้อยแล้ว</h5>
                                        <div class="row">
                                            <p>
                                                ชำระผ่านแล้ว จำนวน {{ currentUser.teacher_info.amount }} บาท <br />
                                                ค่าดำเนินการ และค่าบริการทางอินเตอร์เนต จำนวน {{ currentUser.teacher_info.fee }}
                                                บาท<br />
                                                เมื่อ {{ currentUser.teacher_info.date }} {{ currentUser.teacher_info.time }}
                                            </p>
                                            <b-button type="button" lg="5" @click="showReceiptModal = !showReceiptModal"
                                                variant="primary">
                                                ใบเสร็จรับเงิน
                                            </b-button>
                                            <hr class="my-3" />
    
                                            <h6>ดาวน์โหลดแบบคำขอฯด้านล่าง เพื่อกรอกแบบคำขอฯ ตามข้อมูลที่แจ้งไว้ในระบบ แล้วจัดส่ง
                                                ใบสมัครฯ พร้อมแนบเอกสารประกอบ ส่งไปตามที่อยู่ดังนี้</h6>
                                            <div class="card border border-primary">
                                                <div class="card-body">
                                                    <h5>ถึง นายกสภาการแพทย์แผนไทย</h5>
                                                    <h5>
                                                        (ขึ้นทะเบียนครูแพทย์แผนไทยต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทยและการแพทย์ทางเลือก)<br /><br />
                                                        สำนักงานสภาการแพทย์แผนไทย อาคาร 1 ชั้น 4
                                                        กรมการแพทย์แผนไทยและการแพทย์ทางเลือก<br />
                                                        กระทรวงสาธารณสุข ถนนติวานนท์ ตำบลตลาดขวัญ อำเภอเมือง จังหวัดนนทบุรี
                                                        11000
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="mt-3 col-12 d-grid d-block text-center">
                                                <b-button type="button" lg="5" @click="downloadPDF" variant="primary">
                                                    ดาวน์โหลด แบบคำขอฯ เพื่อนำส่ง <br />พร้อมแนบเอกสารประกอบ
                                                </b-button>
                                                <p>กรุณากรอกแบบคำขอฯ ตามข้อมูลที่แจ้งไว้ในระบบ</p>
    
                                                <div class="row justify-content-center">
                                                    <div class="align-self-center col-6">
                                                        <p>
                                                            รายการเอกสารแนบประกอบการยื่นสมัครอบรมครูแพทย์แผนไทย<br />
                                                            (ต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์ แผนไทย) (๒ ซุต/คน)
                                                            ดังนี้<br />
                                                            ๑. แบบคำขอขึ้นทะเบียนสมัครอบรมครูเพทย์แผนไทย<br />
                                                            (ต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไหย)
                                                            กรอกรายละเอียดไห้ครบถ้วน<br />
                                                            ๒. สำเนาบัตรประจำตัวประชาขน หรือบัตรประจำตัวเจ้าหน้าที่ของรัฐ<br />
                                                            หรือเอกสารอื่นที่ทางราชการออกให้ ซึ่งยังไม่หมดอายุ
                                                            (รับรองสำเนาถูกต้อง)<br />
                                                            ๓. หนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทยที่หมดอายุแล้ว<br />
                                                            ๔. ภาพถ่ายหน้าตรง ไม่สวมแว่นตาดำ ถ่ายไม่เกินหกเดือน ขนาด ๑ นิ้ว
                                                            จำนวน ๔ รูป<br />
                                                            (สำหรับติตในหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย)<br />
                                                            ๕. สำเนาใบเสร็จรับเงิน ใบโอนค่าขึ้นทะเบียน<br />
                                                            ๖. สำเนาหลักฐานอื่นๆ ที่เกี่ยวข้อง เช่น
                                                            หลักฐานการเปลี่ยนชื่อตัว-ชื่อสกุล<br />
                                                            ทะเบียนสมรสหนังสือแต่งตั้งยศ เป็นต้น (รับรองสำเนาถูกต้อง)<br />
                                                        </p>
                                                        <!-- <img src="@/assets/images/attachment.jpeg" class="img-fluid"/> -->
                                                    </div>
                                                </div>
                                            </div>
    
                                        </div>
    
                                    </div>
                                </div>
                                <div v-else-if="isDuedate()">
                                    <h2 class="text-center">หมดเขตลงทะเบียน และชำระเงินแล้ว</h2>
                                    <div class="mt-3 text-center d-grid">
                                        <button class="btn btn-block btn-primary btn-lg" type="button" @click="$router.push({ path: '/course-registed' })" >ดูประกาศเพิ่มเติม</button>
                                    </div>
                                </div>
                            </div>

                            <div class="my-3" v-else-if="!showForm()">
                                <hr class="my-3" />
                                <h4>ระบบอบรม และทดสอบ เพื่อต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย</h4>
                                <div class="row">
                                    <div class="col-6 d-grid">
                                        <b-button type="button" lg="5"
                                            @click="$router.push({ path: '/course-video' })"
                                            variant="success">
                                            อบรม
                                        </b-button>
                                    </div>
                                    <div class="col-6 d-grid">
                                        <b-button type="button" lg="5"
                                            @click="$router.push({ path: '/course-test' })"
                                            variant="success">
                                            ทำข้อสอบ
                                        </b-button>
                                    </div>
                                </div>
                                <hr />
                                <h5>ชำระเงิน เรียบร้อยแล้ว</h5>
                                <div class="row">
                                    <p>
                                        ชำระผ่านแล้ว จำนวน {{ currentUser.teacher_info.amount }} บาท <br />
                                        ค่าดำเนินการ และค่าบริการทางอินเตอร์เนต จำนวน {{ currentUser.teacher_info.fee }}
                                        บาท<br />
                                        เมื่อ {{ currentUser.teacher_info.date }} {{ currentUser.teacher_info.time }}
                                    </p>
                                    <b-button type="button" lg="5" @click="showReceiptModal = !showReceiptModal"
                                        variant="primary">
                                        ใบเสร็จรับเงิน
                                    </b-button>
                                    <hr class="my-3" />

                                    <h6>ดาวน์โหลดแบบคำขอฯด้านล่าง เพื่อกรอกแบบคำขอฯ ตามข้อมูลที่แจ้งไว้ในระบบ แล้วจัดส่ง
                                        ใบสมัครฯ พร้อมแนบเอกสารประกอบ ส่งไปตามที่อยู่ดังนี้</h6>
                                    <div class="card border border-primary">
                                        <div class="card-body">
                                            <h5>ถึง นายกสภาการแพทย์แผนไทย</h5>
                                            <h5>
                                                (ขึ้นทะเบียนครูแพทย์แผนไทยต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทยและการแพทย์ทางเลือก)<br /><br />
                                                สำนักงานสภาการแพทย์แผนไทย อาคาร 1 ชั้น 4
                                                กรมการแพทย์แผนไทยและการแพทย์ทางเลือก<br />
                                                กระทรวงสาธารณสุข ถนนติวานนท์ ตำบลตลาดขวัญ อำเภอเมือง จังหวัดนนทบุรี 11000
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="mt-3 col-12 d-grid d-block text-center">
                                        <b-button type="button" lg="5" @click="downloadPDF" variant="primary">
                                            ดาวน์โหลด แบบคำขอฯ เพื่อนำส่ง <br />พร้อมแนบเอกสารประกอบ
                                        </b-button>
                                        <p>กรุณากรอกแบบคำขอฯ ตามข้อมูลที่แจ้งไว้ในระบบ</p>

                                        <div class="row justify-content-center">
                                            <div class="align-self-center col-6">
                                                <p>
                                                    รายการเอกสารแนบประกอบการยื่นสมัครอบรมครูแพทย์แผนไทย<br />
                                                    (ต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์ แผนไทย) (๒ ซุต/คน) ดังนี้<br />
                                                    ๑. แบบคำขอขึ้นทะเบียนสมัครอบรมครูเพทย์แผนไทย<br />
                                                    (ต่ออายุหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไหย)
                                                    กรอกรายละเอียดไห้ครบถ้วน<br />
                                                    ๒. สำเนาบัตรประจำตัวประชาขน หรือบัตรประจำตัวเจ้าหน้าที่ของรัฐ<br />
                                                    หรือเอกสารอื่นที่ทางราชการออกให้ ซึ่งยังไม่หมดอายุ
                                                    (รับรองสำเนาถูกต้อง)<br />
                                                    ๓. หนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทยที่หมดอายุแล้ว<br />
                                                    ๔. ภาพถ่ายหน้าตรง ไม่สวมแว่นตาดำ ถ่ายไม่เกินหกเดือน ขนาด ๑ นิ้ว จำนวน ๔
                                                    รูป<br />
                                                    (สำหรับติตในหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย)<br />
                                                    ๕. สำเนาใบเสร็จรับเงิน ใบโอนค่าขึ้นทะเบียน<br />
                                                    ๖. สำเนาหลักฐานอื่นๆ ที่เกี่ยวข้อง เช่น
                                                    หลักฐานการเปลี่ยนชื่อตัว-ชื่อสกุล<br />
                                                    ทะเบียนสมรสหนังสือแต่งตั้งยศ เป็นต้น (รับรองสำเนาถูกต้อง)<br />
                                                </p>
                                                <!-- <img src="@/assets/images/attachment.jpeg" class="img-fluid"/> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Horizontal>
    <confirmModal :isShow="isShowConfirmModal" v-on:close="closeConfirmModal" v-on:confirm="confirmData"></confirmModal>

    <b-modal v-if="currentUser.teacher_info != null" size="xl" v-model="showReceiptModal" no-close-on-esc
        no-close-on-backdrop hide-header-close centered>
        <Receipt v-if="currentUser.teacher_info != null" :receipt_no="currentUser.teacher_info.id"
            :reference_no="currentUser.teacher_info.referenceNo" :created_dt="currentUser.teacher_info.created_dt"
            :firstname="currentUser.teacher_info.firstname || '-'" :lastname="currentUser.teacher_info.lastname || '-'"
            :phone="currentUser.teacher_info.phone || '-'"
            :identity_no="currentUser.teacher_info.identity_number || '-'" receipt_name="ค่าสมัครอบรมครูแพทย์แผนไทย"
            :amount="currentUser.teacher_info.amount"
            :fee="currentUser.teacher_info.fee ? currentUser.teacher_info.fee.toFixed(2) : currentUser.teacher_info.fee"
            :total="currentUser.teacher_info.amount && currentUser.teacher_info.fee ? (currentUser.teacher_info.amount + currentUser.teacher_info.fee).toFixed(2) : (currentUser.teacher_info.amount + currentUser.teacher_info.fee)">
            ></Receipt>
        <template #modal-footer>
            <div class="col-5 d-block d-grid">
                <b-button variant="" size="xl" @click="showReceiptModal = !showReceiptModal;">
                    <h3 class="text-white">ปิด</h3>
                </b-button>
            </div>
        </template>
    </b-modal>
</div></template>
