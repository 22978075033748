<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            required: true,
        },
    },
    components: {
    },
    data() {
        return {
            pdfHeight: window.innerHeight * 0.3,
        }
    },
}
</script>

<template>
    <b-modal size="md" v-model="isShow" title="ยืนยันการส่งข้อมูล ?" no-close-on-esc no-close-on-backdrop hide-header-close>
        <h4 class="text-warning">หากต้องการแก้ไข ให้กด "ย้อนกลับไปแก้ไขข้อมูล"</h4>
        <h4 class="text-warning">หากต้องการยืนยัน ให้กด "ยืนยัน"</h4>
        <h4 class="text-danger">หลังจากกด "ยืนยัน" แล้ว จะไม่สามารถเปลี่ยนแปลงข้อมูลได้</h4>
        <template #modal-footer>
            <div class="w-100 d-block d-grid">
                <b-button variant="success" size="xl" @click="$emit('close');">
                    ย้อนกลับไปแก้ไขข้อมูล
                </b-button>
            </div>
            <div class="w-100 d-block d-grid">
                <b-button variant="warning" size="xl" @click="$emit('confirm');">
                    ยืนยัน
                </b-button>
            </div>
        </template>
    </b-modal>
</template>